import { supabase } from 'lib/supabase'

import { errorLog } from 'utils/log'
import { formatPhoneDisplay } from 'utils/string'
import { chartLogsSeperated } from 'utils/charts'
import { timestampsToDate } from 'utils/schedule'

export const getListingByMagicnumbr = async (supabaseAdmin, number) => {
  try {
    const { data, error } = await supabaseAdmin
      .from('listings')
      .select('*')
      .eq('magicnumbr', number)
      .eq('active', true)
      .order('created_at', { ascending: false })
      .limit(1)

    if (error) {
      throw new Error(error)
    }

    return data[0]
  } catch (error) {
    errorLog(error)
  }
}

export const getListingById = async (supabaseAdmin, id) => {
  try {
    const { data, error } = await supabaseAdmin.from('listings').select('*').eq('id', id)

    if (error) {
      throw new Error(error)
    }

    if (data.length > 0) {
      return data[0]
    } else {
      false
    }
  } catch (error) {
    errorLog(error)
  }
}

export const getListings = async (supabaseAdmin) => {
  try {
    const { data, error } = await supabaseAdmin.from('listings').select('*')

    if (error) {
      throw new Error(error)
    }

    return data
  } catch (error) {
    errorLog(error)
  }
}

export const addListing = async (supabaseAdmin, insert_data) => {
  const { data, error } = await supabaseAdmin.from('listings').insert(insert_data, {
    returning: 'minimal',
  })

  if (error) {
    throw new Error(error)
  }

  return data
}

export const getMyListings = async () => {
  try {
    const { data, error } = await supabase
      .from('listings')
      .select(
        `
        id, address, magicnumbr, to_number, notify_numbers, session_timeout, active, note,
        logs ( listing_id, type, timestamp )
      `
      )
      .order('created_at', { ascending: false })

    if (error) {
      throw new Error(error)
    }

    const listings = data.map((listing) => {
      const calls = listing?.logs.filter((item) => item.type == 'voice').length
      const txts = listing?.logs.filter((item) => item.type == 'sms').length

      const magicnumbr = formatPhoneDisplay(listing.magicnumbr)
      const to_number = formatPhoneDisplay(listing.to_number)
      const notify_numbers = listing.notify_numbers.map((number) => formatPhoneDisplay(number))

      return {
        link: `/dashboard/listings/${listing.id}`,
        magicnumbr: magicnumbr,
        to_number: to_number,
        notify_numbers: notify_numbers,
        note: listing.note,
        title: listing.address.street,
        calls: calls,
        txts: txts,
        active: listing.active == true,
      }
    })

    return listings
  } catch (error) {
    errorLog(error)
  }
}

export const getListing = async (id) => {
  try {
    const { data, error } = await supabase
      .from('listings')
      .select(
        `
        id, address, magicnumbr, to_number, notify_numbers, session_timeout, active, note, auto_response, auto_response_text, 
        logs ( listing_id, type, timestamp ),
        schedules ( sunday, monday, tuesday, wednesday, thursday, friday, saturday )
      `
      )
      .eq('id', id)
      .order('timestamp', { foreignTable: 'logs' })

    if (error) {
      throw new Error(JSON.stringify(error))
    }

    if (data && data.length > 0) {
      const listings = data.map((listing) => {
        const logs = listing?.logs.length > 0 ? chartLogsSeperated(listing.logs) : {}
        const schedules = listing?.schedules.length > 0 ? timestampsToDate(listing.schedules[0]) : []

        return {
          id: listing.id,
          link: `/dashboard/listings/${listing.id}`,
          magicnumbr: listing.magicnumbr,
          note: listing.note,
          to_number: listing.to_number,
          notify_numbers: listing.notify_numbers,
          address: listing.address,
          auto_response: listing.auto_response,
          auto_response_text: listing.auto_response_text,
          logs: logs,
          active: listing.active,
          schedules: schedules,
        }
      })

      if (listings && listings.length > 0) {
        return listings.shift()
      } else {
        return false
      }
    } else {
      return false
    }
  } catch (error) {
    errorLog(error)
  }
}

export const saveListing = async (listing_data, schedules_data) => {
  try {
    if (schedules_data) {
      const { data: schedules, error: schedules_error } = await supabase
        .from('schedules')
        .update(schedules_data)
        .eq('listing_id', listing_data.id)

      if (schedules_error) {
        throw new Error(JSON.stringify(schedules_error))
      }
    }

    const { data, error } = await supabase.from('listings').update(listing_data).eq('id', listing_data.id).select()

    if (error) {
      throw new Error(JSON.stringify(error))
    }

    return {
      success: data && data.length > 0,
      data: data,
    }
  } catch (error) {
    errorLog(error)
  }
}

export const deactivateListing = async (id) => {
  try {
    const { data, error } = await supabase.from('listings').update({ active: false }).eq('id', id)

    if (error) {
      throw new Error(JSON.stringify(error))
    }

    return {
      success: data && data.length > 0,
      data: data,
    }
  } catch (error) {
    errorLog(error)
  }
}

export const activateListing = async (id) => {
  try {
    const { data, error } = await supabase.from('listings').update({ active: true }).eq('id', id)

    if (error) {
      throw new Error(JSON.stringify(error))
    }

    return {
      success: data && data.length > 0,
      data: data,
    }
  } catch (error) {
    errorLog(error)
  }
}

export const getListingHistory = async (listing_id) => {
  try {
    const listing = await getListing(listing_id)
    const { data, error } = await supabase
      .from('logs')
      .select('*')
      .eq('listing_id', listing_id)
      .order('timestamp', { ascending: false })

    if (error) {
      throw new Error(JSON.stringify(error))
    }
    const log_data = data.map((log) => {
      return {
        type: log.type,
        timestamp: log.timestamp,
        session_id: log.session_id,
        to_phone: log.to_phone,
        to_phone_proxy: log.to_phone_proxy,
        from_phone: log.from_phone,
        from_phone_proxy: log.from_phone_proxy,
      }
    })

    return {
      success: log_data && log_data.length > 0,
      data: log_data,
    }
  } catch (error) {
    errorLog(error)
  }
}
