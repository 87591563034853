import ShortUniqueId from 'short-unique-id'
const uid = new ShortUniqueId({ length: 8 })

import parsePhoneNumber from 'libphonenumber-js'

export const uniqueNameListing = (id) => {
  return `listing_${id}_${uid()}`
}

export const parseUniqueName = (unique_name) => {
  const unique_listing_array = unique_name.split('_')
  const full_listing_id = unique_listing_array[1]

  return full_listing_id
}

export const formatPhoneDisplay = (number) => {
  const parsed = parsePhoneNumber(number, 'US')

  return parsed.format('NATIONAL')
}

export const formatPhoneForm = (number) => {
  const parsed = parsePhoneNumber(number, 'US')

  return parsed.number
}

export const formatPhoneForInput = (number) => {
  const parsed = parsePhoneNumber(number, 'US')

  return parsed.nationalNumber
}
