import * as Sentry from '@sentry/nextjs'

export const log = (message) => {
  Sentry.captureMessage(message, 'info')
  console.log(message)
}

export const errorLog = (message) => {
  Sentry.captureMessage(message, 'error')
  console.log(message)
}
