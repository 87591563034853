import { format } from 'date-fns'

export const timestampsToDate = (days) => {
  const all_days = []
  const days_array = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
  days_array.forEach((day) => {
    if (days[day]?.length) {
      days[day].forEach((slot) => all_days.push(new Date(parseInt(slot))))
    }
  })

  return all_days
}

export const datesToTimestamps = (days) => {
  const all_days = { sunday: [], monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [] }

  days.forEach((item) => {
    const day = format(item, 'EEEE').toLowerCase()
    const timestamp = new Date(item).getTime().toString()

    all_days[day].push(timestamp)
  })

  return all_days
}
