import { supabase } from 'lib/supabase'
import { isofetch } from 'utils/fetch'

import { errorLog } from 'utils/log'

export const getSubscription = async (supabaseAdmin, user_id) => {
  const { data, error } = await supabaseAdmin
    .from('subscriptions')
    .select(
      `
      *, prices(*, products(*))`
    )
    .eq('user_id', user_id)
    .limit(1)

  if (error) {
    throw new Error(error)
  }

  if (data.length > 0) {
    return data[0]
  } else {
    false
  }
}

export const getProfile = async (supabaseAdmin, user_id) => {
  const { data, error } = await supabaseAdmin
    .from('profiles')
    .select(`username, phone, email, billing_address, default_notify_sms, preferred_area_code`)
    .limit(1)
    .eq('id', user_id)

  if (error) {
    throw new Error(error)
  }

  if (data.length > 0) {
    return data[0]
  } else {
    false
  }
}

export const getMyProfile = async () => {
  try {
    const { data, error } = await supabase
      .from('profiles')
      .select(`phone, email, billing_address, default_notify_sms, preferred_area_code, first_name, last_name`)
      .limit(1)

    if (error) {
      throw new Error(JSON.stringify(error))
    }

    if (data.length > 0) {
      return data[0]
    } else {
      false
    }
  } catch (error) {
    errorLog(error)
  }
}

export const saveMyProfile = async (profle_data) => {
  try {
    const { data, error } = await supabase.from('profiles').upsert(profle_data)

    if (error) {
      throw new Error(JSON.stringify(error))
    }

    return data && data.length > 0
  } catch (error) {
    errorLog(error)
  }
}

export const getMySubscription = async () => {
  try {
    const { data, error } = await supabase.from('subscriptions').select(`
        id, status, metadata, price_id, current_period_end, cancel_at_period_end, 
        prices(id, product_id, active, description, unit_amount, interval, interval_count, metadata, 
        products(name, active, description, metadata))`)

    if (error) {
      throw new Error(JSON.stringify(error))
    }

    if (data.length > 0) {
      return data[0]
    } else {
      false
    }
  } catch (error) {
    errorLog(error)
  }
}

export const getUserDetails = async () => {
  try {
    const { data, error } = await supabase.from('profiles').select('*')

    if (error) {
      throw new Error(error)
    }

    if (data.length > 0) {
      return data[0]
    } else {
      false
    }
  } catch (error) {
    console.error(error)
  }
}

export const setUserDetails = async (user_id, user_data) => {
  try {
    //  Move this to an API/Admin feature to avoid row level security issue
    const data = await isofetch('/api/user/profile', 'POST', {
      id: user_id,
      ...user_data,
    })

    if (data?.success) {
      return data.response
    } else {
      throw new Error(data.error)
    }
  } catch (error) {
    console.error(error)
  }
}

export const checkIfUserExists = async () => {
  // const user_exists = await isofetch('/api/')
}

export const login = async (phone) => {
  try {
    let { user, error } = await supabase.auth.signIn({
      phone: phone,
    })

    if (error) {
      console.log(error)
      throw new Error(error.message)
    }

    return {
      success: true,
      user: user,
    }
  } catch (error) {
    return {
      success: false,
      error: 'Something went wrong, please try again later.',
    }
  }
}

export const signup = async (phone, first_name, last_name) => {
  try {
    const random_password = Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2)
    let { user, error } = await supabase.auth.signUp({
      phone: phone,
      password: random_password,
    })

    const new_profile = await setUserDetails(user.id, {
      phone: phone,
      first_name: first_name || '',
      last_name: last_name || '',
    })

    if (error) {
      // console.log(error);
      throw new Error(error.message)
    }

    return {
      success: true,
      user: user,
      profile: new_profile,
    }
  } catch (error) {
    return {
      success: false,
      error: 'Something went wrong, please try again later.',
    }
  }
}

export const verifyToken = async (phone, token) => {
  try {
    const { session, error } = await supabase.auth.verifyOTP({
      phone: phone,
      token: token,
    })

    if (error) {
      return {
        success: false,
        error: error,
      }
    } else {
      return {
        success: true,
        session: session,
      }
    }
  } catch (error) {
    return {
      success: false,
      error: 'Something went wrong verifying code.',
    }
  }
}

export const resetPassword = async (email) => {
  try {
    const { data, error } = await supabase.auth.api.resetPasswordForEmail(email)

    if (error) {
      throw new Error(error)
    }

    return data
  } catch (error) {
    console.error(error)
  }
}

export const updatePassword = async (password) => {
  try {
    const { error } = await supabase.auth.update({ password })

    if (error) {
      throw new Error(error)
    }

    return true
  } catch (error) {
    console.error(error)
  }
}

export const notificationMesage = ({ type, street, from_number, total_calls, total_sms }) => {
  return `The listing on ${street} just received a ${type} from ${from_number}. ${total_calls} calls and ${total_sms} text messages have been receieved so far for scheduling showings`
}
