import fetch from 'isomorphic-unfetch'

export function isofetch(url, type, data = false, headers = {}) {
  let args = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
    method: type,
  }

  if (type == 'POST' || type == 'PUT' || type == 'DELETE') {
    if (data) {
      args.body = JSON.stringify({ ...data })
    }
  }

  if (type == 'GET' && data) {
    var params_str = ''
    for (var key in data) {
      if (params_str != '') {
        params_str += '&'
      }
      params_str += key + '=' + encodeURIComponent(data[key])
    }

    url = `${url}?${params_str}`
  }

  return fetch(`${url}`, args)
    .then((response) => {
      return response.json()
    })
    .then((response) => {
      if (response === 'TypeError: Failed to fetch') {
        throw Error('Server error.')
      }
      return response
    })
    .catch((error) => {
      throw error
    })
}
