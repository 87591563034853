import { useEffect, useState, createContext, useContext, unstable_batchedUpdates } from 'react'

import { supabase } from 'lib/supabase'
import { isofetch } from 'utils/fetch'

import { getMyListings } from 'lib/listings'
import { getMyProfile, getMySubscription } from 'lib/user'

export const AppStoreContext = createContext()

export const AppContextProvider = (props) => {
  const [listings, setListings] = useState()
  const [listingsLoading, setListingsLoading] = useState(true)
  const [archivedListings, setArchivedListings] = useState()
  const [allListings, setAllListings] = useState()
  const [profile, setProfile] = useState()
  const [subscription, setSubscription] = useState()

  const [session, setSession] = useState()
  const [user, setUser] = useState()
  const [passwordReset, setPasswordReset] = useState(false)

  useEffect(() => {
    const fresh_session = supabase.auth.session()
    setSession(fresh_session)
    setUser(fresh_session?.user ?? null)

    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      setSession(session)
      setUser(session?.user ?? null)
      updateSupabaseCookie(event, session)
    })

    return () => {
      authListener?.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user) {
      getListings()
      getProfile()
      getSubscription()
    }
  }, [user])

  const getListings = async () => {
    setListingsLoading(true)
    const data = await getMyListings()
    if (data) {
      setAllListings(data)

      const active_listings = data.filter((listing) => listing.active)
      const archived_listings = data.filter((listing) => listing.active == false)

      setListings(active_listings)
      setArchivedListings(archived_listings)
      setListingsLoading(false)
    }
  }

  const getProfile = async () => {
    const data = await getMyProfile()
    if (data) {
      setProfile(data)
    }
  }

  const getSubscription = async () => {
    const data = await getMySubscription()
    if (data) {
      const sub_data = {
        active: data.status == 'active' ? true : false,
        max_listings: parseInt(data?.prices?.metadata?.credits),
      }

      setSubscription(sub_data)
    }
  }

  const updateSupabaseCookie = async (event, session) => {
    await isofetch('/api/user/auth', 'POST', {
      event: event,
      session: session,
    })
  }

  const setUserSession = async (token) => {
    await supabase.auth.setSession(token)
  }

  const hasMaxListings = () => {
    let isMax = false
    if (listings?.length >= subscription?.max_listings) {
      isMax = true
    }

    return isMax
  }

  const value = {
    session,
    setUserSession,
    user,
    profile,
    subscription,
    listings,
    archivedListings,
    getListings,
    listingsLoading,
    passwordReset,
    hasMaxListings,
    signIn: (options) => supabase.auth.signIn(options),
    signUp: (options) => supabase.auth.signUp(options),
    signOut: () => supabase.auth.signOut(),
  }

  return <AppStoreContext.Provider value={value} {...props} />
}

export const useAppStore = () => {
  const context = useContext(AppStoreContext)
  if (context === undefined) {
    throw new Error(`useAppStore must be used within a UserContextProvider.`)
  }
  return context
}
