// import { Provider, ErrorBoundary } from '@rollbar/react';

import { supabase } from 'lib/supabase'
import { AppContextProvider } from 'utils/appStore'

import '../styles/globals.css'

const rollbarConfig = {
  accessToken: `${process.env.NEXT_PUBLIC_ROLLBAR_TOKEN}`,
  environment: `${process.env.NEXT_PUBLIC_NODE_ENV}`,
}

function MagicNumbr({ Component, pageProps }) {
  return (
    // <Provider config={rollbarConfig}>
    //   <ErrorBoundary>
    <AppContextProvider>
      <Component {...pageProps} />
    </AppContextProvider>
    //   </ErrorBoundary>
    // </Provider>
  )
}

export default MagicNumbr
