export const chartLogsSeperated = (logs) => {
  if (logs.length == 0) {
    return {}
  }

  const call_logs = logs.filter((item) => item.type == 'voice')
  const txt_logs = logs.filter((item) => item.type == 'sms')

  const calls = call_logs.length > 0 ? logsToTimeline(call_logs) : []
  const txts = txt_logs.length > 0 ? logsToTimeline(txt_logs) : []

  return {
    calls: calls,
    call_count: call_logs.length || 0,
    txts: txts,
    txt_count: txt_logs.length || 0,
  }
}

const logsToTimeline = (logs) => {
  let dates = []

  const start_date = new Date(logs[0].timestamp)
  start_date.setDate(start_date.getDate() - 2)
  const end_date = new Date(logs[logs.length - 1].timestamp)
  const days = Math.ceil((end_date - start_date) / 1000 / 60 / 60 / 24) + 2

  for (var i = 0; i < days; i++) {
    const current_day = start_date
    current_day.setDate(current_day.getDate() + 1)
    const day_of_year = Math.floor(
      (new Date(current_day) - new Date(current_day.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24
    )

    let logs_from_current_day = logs.filter((item, i) => {
      const log_day_of_year = Math.floor(
        (new Date(item.timestamp) - new Date(new Date(item.timestamp).getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24
      )

      return log_day_of_year == day_of_year
    })

    const current_day_object = {
      // day: day_of_year,
      date: current_day.toISOString(),
      counts: logs_from_current_day.length,
    }

    dates.push(current_day_object)
  }

  return {
    dates: dates.map((item) => item.date),
    counts: dates.map((item) => item.counts),
  }
}

export const chartLogs = (logs) => {
  const data = {
    calls: [],
    txts: [],
  }

  const call_logs = logs.filter((item) => item.type == 'voice')
  let group_calls = call_logs.reduce((items, item) => {
    const day_date = moment(item.timestamp).format('MMM D')

    if (!items[day_date]) {
      items[day_date] = 0
    }

    items[day_date] += 1
    return items
  }, {})

  const calls = Object.keys(group_calls).map((item) => {
    return {
      date: item,
      count: group_calls[item],
    }
  })

  const txt_logs = logs.filter((item) => item.type == 'sms')
  const group_txts = txt_logs.reduce((items, item) => {
    const day_date = moment(item.timestamp).format('MMM D')

    if (!items[day_date]) {
      items[day_date] = 0
    }

    items[day_date] += 1
    return items
  }, {})

  const txts = Object.keys(group_txts).map((item) => {
    return {
      date: item,
      count: group_txts[item],
    }
  })

  data.calls = calls
  data.txts = txts

  return data
}
